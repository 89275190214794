export type Day = {
  date: string;
  isSelectable?: boolean;
  isToday?: boolean;
  isSelected?: boolean;
  isWeekend?: boolean;
  isCurrentMonth?: boolean;
};

export function formatDate(date: Date): string {
  // day is 1-indexed
  const day = String(date.getDate()).padStart(2, "0");
  // month is 0-indexed
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function generateCalendarMonth(
  year: number,
  month: number,
  selectedDate: string,
  minSelectableDate: string,
  maxSelectableDate: string,
): Day[] {
  const days: Day[] = [];

  // Determine the first day of the month
  const firstDayOfMonth = new Date(year, month, 1).getDay();

  // Calculate the date to start the calendar
  const currentDate = new Date(year, month, 1 - firstDayOfMonth);

  for (let week = 0; week < 6; week++) {
    // if the last week has no days from the current month, stop
    if (currentDate.getMonth() > month && month !== 0) {
      break;
    }

    for (let day = 0; day < 7; day++) {
      const dateString = formatDate(currentDate);
      const isWeekend = [0, 6].includes(currentDate.getDay());
      const isCurrentMonth = currentDate.getMonth() === month;
      days.push({
        date: dateString,
        isToday: dateString === minSelectableDate,
        isSelected: dateString === selectedDate,
        isWeekend,
        isSelectable:
          !isWeekend &&
          dateString >= minSelectableDate &&
          dateString <= maxSelectableDate &&
          isCurrentMonth,
        isCurrentMonth,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return days;
}

export function splitDate(dateString: string): [number, number, number] {
  const [year, month, day] = dateString.split("-");
  return [Number(year), Number(month) - 1, Number(day)];
}

export function getFullDate(dateString: string): string {
  // convert a date string like "2022-04-28" to "April 28, 2022"
  const [year, month, day] = splitDate(dateString);
  const date = new Date(year, month, day);
  return date.toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
