import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAccount } from "../page/account";

export type RequireServiceProps = {
  redirect: string;
  children: ReactNode;
};

export const RequireService: React.FC<RequireServiceProps> = ({
  redirect,
  children,
}) => {
  const account = useAccount();
  if (!account.enrollment?.is_service_active) {
    return <Navigate to={redirect} />;
  }
  return children;
};
