import { useLogout } from "@/utils/logout";
import { EmptyHeader } from "./EmptyHeader";
import { useScaffold } from "@Light/scaffold";
import { useAccount } from "@Light/components/page/account";
import { ReactNode } from "react";
import { useLight } from "@Light/services/light";
import { useMutation } from "@Light/utils/mutation";
import { Navigate } from "react-router-dom";

export type ServiceAlreadyActiveProps = {
  title?: string;
};

export const ServiceAlreadyActive: React.FC<ServiceAlreadyActiveProps> = ({
  title,
}) => {
  const logout = useLogout("service_already_active");
  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;

  const companyName = scaffold.page.companyName;

  const account = useAccount();
  const location = account.locations.length > 0 ? account.locations[0] : null;
  const finalServiceDate = location?.final_service_date;

  const { useResetEnrollmentMutation } = useLight();
  const resetEnrollment = useMutation(useResetEnrollmentMutation);

  let titleText = title || "Your service is active";
  let subtitleText = `You can manage your service within the ${companyName} platform.`;
  let subtitleText2: ReactNode | null = null;

  const Button = scaffold.page.button;

  if (
    resetEnrollment.isSuccess &&
    !account.enrollment?.is_enrollment_finalized
  ) {
    return <Navigate to="/" />;
  }

  let cancelText = "Okay";

  if (finalServiceDate) {
    cancelText = "Not now";
    // replace - with / to make the date local timezone instead of UTC
    const date = new Date(finalServiceDate.replace(/-/g, "/"));
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    titleText = "Your service was cancelled on " + formattedDate;
    subtitleText = `You can view information about your service within the ${companyName} platform.`;

    subtitleText2 = (
      <div className="flex flex-col items-center gap-4 w-full">
        <div className="mb-4">Would you like to enroll in a new plan?</div>
        <Button
          onClick={() => {
            resetEnrollment.mutate();
          }}
          className="w-full"
        >
          See rates and enroll
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <EmptyHeader />
      <div className="flex justify-center items-center text-center h-full px-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-6">
            <Title>{titleText}</Title>
            <Subtitle>{subtitleText}</Subtitle>
            {subtitleText2 && <Subtitle>{subtitleText2}</Subtitle>}
          </div>
          <Button
            buttonType={finalServiceDate ? "outline" : "plain"}
            onClick={logout}
          >
            {cancelText}
          </Button>
        </div>
      </div>
    </div>
  );
};
