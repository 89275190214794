import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAccount } from "@Light/components/page/account";

export type RequireNoServiceProps = {
  redirect: string;
  children: ReactNode;
};

export const RequireNoService: React.FC<RequireNoServiceProps> = ({
  redirect,
  children,
}) => {
  const account = useAccount();

  // if the service is active, redirect to the redirect path
  if (account.enrollment?.is_service_active) {
    return <Navigate to={redirect} />;
  }

  const location = account.locations.length > 0 ? account.locations[0] : null;

  // also for now if the final service date is set, is_service_active will be false
  // but we want to redirect to the redirect path to avoid confusion
  if (
    location?.final_service_date &&
    account.enrollment?.is_enrollment_finalized
  ) {
    return <Navigate to={redirect} />;
  }

  return children;
};
